<template>
  <div class="wrapper">
    <div class="contact-title-bar">
      <div class="contact-title">成都总公司</div>
    </div>
    <div class="contact-bar">
      <div class="item">
        <img src="@/assets/icon/ic_locate_white.svg" alt="locate">
        <div class="subtitle">地址</div>
        <div>成都市高新区天府三街吉泰五路88号</div>
      </div>
      <div class="item">
        <img src="@/assets/icon/ic_phone_white.svg" alt="locate">
        <div class="subtitle">座机</div>
        <div>028-85534402/3/5/9</div>
      </div>
      <div class="item">
        <img src="@/assets/icon/ic_email_white.svg" alt="locate">
        <div class="subtitle">邮箱</div>
        <div>greatech@vip.163.com</div>
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <div class="title">联系我们</div>
        <div>
          地址：<span>中国四川省成都市高新区吉泰五路88号花样年香年广场T3-2501</span>
        </div>
        <div>
          网址：<span>m.greatech.com.cn</span>
        </div>
        <div>
          总机：<span>028-85534402/3/5/9</span>
        </div>
        <div>
          E-mail：<span>greatech@vip.163.com</span>
        </div>
      </div>
      <div class="right">
        <img src="@/assets/map.png" alt="map" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: calc(100% - 600px);
  height: 100%;
  margin: 0 auto;

  .contact-title-bar {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin: 80px 0;

    .contact-title {
      border-left: 8px solid #1b57ca;
      font-size: 24px;
      font-weight: bold;
      padding-left: 10px;
    }
  }

  .contact-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 40px 0;

    .item {
      width: 280px;
      height: 280px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding: 60px 0;
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      border-bottom: 4px solid #1b57ca;

      .subtitle {
        font-weight: bold;
      }

      img {
        width: 60px;
        height: 60px;
        padding: 16px;
        border-radius: 50%;
        background: #1b57ca;
      }
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 40px;

      .title {
        margin: 20px 0;
      }
    }

    .right {
      img {
        width: 400px;
        height: 300px;
        object-fit: cover;
      }
    }
  }
}
</style>
